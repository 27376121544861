define("ember-bootstrap/utils/decorators/uses-transition", ["exports", "ember-bootstrap/utils/is-fastboot"], function (_exports, _isFastboot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = arg;
  function arg(fadeProperty) {
    (true && !(typeof fadeProperty === 'string') && Ember.assert('You have to provide a fadeProperty for typeClass', typeof fadeProperty === 'string'));
    return function () {
      return {
        get: function get() {
          return !(0, _isFastboot.default)(this) && this.args[fadeProperty] !== false;
        }
      };
    };
  }
});