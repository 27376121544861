define("ember-bootstrap/utils/cp/overrideable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = overrideableCP;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  /**
   * CP macro that created a regular computed property, which can be manually overriden.
   * This is needed after implicitly overrideable CPs have been deprecated:
   * https://deprecations-app-prod.herokuapp.com/deprecations/v3.x/#toc_computed-property-override
   *
   * @private
   */
  function overrideableCP() {
    var _Ember;
    var fn = Array.prototype.slice.call(arguments, -1)[0];
    var args = Array.prototype.slice.call(arguments, 0, arguments.length - 1);
    (true && !(typeof fn === 'function') && Ember.assert('Last argument for overrideableCP must be a function', typeof fn === 'function'));
    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(args).concat([{
      get: function get(key) {
        var overridden = this["__".concat(key)];
        return overridden || fn.call(this);
      },
      set: function set(key, value) {
        this["__".concat(key)] = value;
        return value;
      }
    }]));
  }
});