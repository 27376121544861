define("ember-bootstrap/components/bs-modal/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="modal-body" ...attributes>
    {{yield}}
  </div>
  */
  {
    "id": "QpOEJct9",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"modal-body\"],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-bootstrap/components/bs-modal/body.hbs"
    }
  });
  /**
  
   Modal body element used within [Components.Modal](Components.Modal.html) components. See there for examples.
  
   @class ModalBody
   @namespace Components
   @extends Glimmer.Component
   @public
   */
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});