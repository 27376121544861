define("ember-bootstrap/version", ["exports", "@embroider/macros/runtime"], function (_exports, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VERSION = void 0;
  _exports.registerLibrary = registerLibrary;
  var VERSION = _exports.VERSION = (0, _runtime.config)("/home/runner/work/imago-admin/imago-admin/node_modules/.pnpm/ember-bootstrap@4.9.0_@babel+core@7.26.0/node_modules/ember-bootstrap").version;
  function registerLibrary() {
    Ember.libraries.register('Ember Bootstrap', VERSION);
  }
});