define("ember-named-blocks-polyfill/helpers/-has-block", ["exports", "ember-named-blocks-polyfill"], function (_exports, _emberNamedBlocksPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var _default = _exports.default = Ember.Helper.helper(
  /**
   * @param {unknown[]} params
   * @param {object} hash
   * @returns {boolean}
   */
  function _hasBlock(params, hash) {
    var _params = _slicedToArray(params, 3),
      blocksInfo = _params[0],
      block = _params[1],
      fallback = _params[2];
    (true && !(params.length === 3 && (blocksInfo === undefined || (0, _emberNamedBlocksPolyfill.isBlocksInfo)(blocksInfo)) && typeof block === 'string' && typeof fallback === 'boolean') && Ember.assert('-has-block takes exactly three arguments: the blocks info ' + 'hash, the name of the block and the fallback value', params.length === 3 && (blocksInfo === undefined || (0, _emberNamedBlocksPolyfill.isBlocksInfo)(blocksInfo)) && typeof block === 'string' && typeof fallback === 'boolean'));
    (true && !(Object.keys(hash).length === 0) && Ember.assert('-is-named-block-invocation does not take named arguments', Object.keys(hash).length === 0));
    return blocksInfo ? block in blocksInfo : fallback;
  });
});