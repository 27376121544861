define("ember-fetch/ajax", ["exports", "fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = ajax;
  function ajax(input, init) {
    return (0, _fetch.default)(input, init).then(function (response) {
      if (response.ok) {
        return response.json();
      }
      throw response;
    });
  }
});