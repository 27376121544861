define("ember-cli-mirage/utils/read-modules", ["exports", "ember-cli-mirage/assert", "miragejs", "ember-inflector", "require"], function (_exports, _assert, _miragejs, _emberInflector, _require) {
  /* global requirejs */
  /* eslint-env node */
  'use strict';

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /**
    This function looks through all files that have been loaded by Ember CLI and
    finds the ones under /mirage/[factories, fixtures, scenarios, models]/, and exports
    a hash containing the names of the files as keys and the data as values.
  
    @hide
  */
  function _default(prefix) {
    var modules = ['factories', 'fixtures', 'scenarios', 'models', 'serializers', 'identity-managers'];
    var mirageModuleRegExp = new RegExp("^".concat(prefix, "/mirage/(").concat(modules.join('|'), ")"));
    var modulesMap = modules.reduce(function (memo, name) {
      memo[(0, _miragejs._utilsInflectorCamelize)(name)] = {};
      return memo;
    }, {});
    Object.keys(requirejs.entries).filter(function (key) {
      return mirageModuleRegExp.test(key);
    }).forEach(function (moduleName) {
      if (moduleName.match('.jshint')) {
        // ignore autogenerated .jshint files
        return;
      }
      var moduleParts = moduleName.split('/');
      var moduleTypeIndex = moduleParts.indexOf('mirage') + 1;
      var moduleType = (0, _miragejs._utilsInflectorCamelize)(moduleParts[moduleTypeIndex]);
      var moduleKey = moduleParts.slice([moduleTypeIndex + 1]).join('/');
      if (moduleType === 'scenario') {
        (0, _assert.default)('Only scenario/default.js is supported at this time.', moduleKey !== 'default');
      }

      /*
      Ensure fixture keys are pluralized
      */
      if (moduleType === 'fixtures') {
        moduleKey = (0, _emberInflector.pluralize)(moduleKey);
      }
      var module = (0, _require.default)(moduleName, null, null, true);
      if (!module) {
        throw new Error("".concat(moduleName, " must export a ").concat(moduleType));
      }
      var data = module.default;
      modulesMap[moduleType][(0, _miragejs._utilsInflectorCamelize)(moduleKey)] = data;
    });
    return modulesMap;
  }
});