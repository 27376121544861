define("ember-concurrency/-private/task-decorators", ["exports", "ember-concurrency/-private/external/task-decorators", "ember-concurrency/-private/task-factory", "ember-concurrency/-private/utils"], function (_exports, _taskDecorators, _taskFactory, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.taskGroup = _exports.task = _exports.restartableTaskGroup = _exports.restartableTask = _exports.lastValue = _exports.keepLatestTaskGroup = _exports.keepLatestTask = _exports.enqueueTaskGroup = _exports.enqueueTask = _exports.dropTaskGroup = _exports.dropTask = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var computedLastValue = (0, _taskDecorators.decoratorWithParams)(function (target, key, descriptor) {
    var _ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [],
      _ref2 = _slicedToArray(_ref, 1),
      taskName = _ref2[0];
    var initializer = descriptor.initializer;
    delete descriptor.initializer;
    var cp = Ember.computed("".concat(taskName, ".lastSuccessful"), function () {
      var lastInstance = Ember.get(this, "".concat(taskName, ".lastSuccessful"));
      if (lastInstance) {
        // eslint-disable-next-line ember/no-get
        return Ember.get(lastInstance, 'value');
      }
      if (initializer) {
        return initializer.call(this);
      }
      return undefined;
    });
    return cp(target, key, descriptor);
  });
  var lastValue = _exports.lastValue = _utils.USE_TRACKED ? _taskDecorators.lastValue : computedLastValue;

  /**
   * A Task is a cancelable, restartable, asynchronous operation that
   * is driven by a generator function. Tasks are automatically canceled
   * when the object they live on is destroyed (e.g. a Component
   * is unrendered).
   *
   * Turns the decorated generator function into a task.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, `group` or `keepLatest`.
   *
   * By default, tasks have no concurrency constraints
   * (multiple instances of a task can be running at the same time)
   * but much of a power of tasks lies in proper usage of Task Modifiers
   * that you can apply to a task.
   *
   * You can also define an
   * <a href="/docs/advanced/encapsulated-task">Encapsulated Task</a>
   * by decorating an object that defines a `perform` generator
   * method.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;task
   *   *plainTask() {}
   *
   *   &#64;task({ maxConcurrency: 5, keepLatest: true, cancelOn: 'click' })
   *   *taskWithModifiers() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task modifier options
   * @param {string|string[]} [options.cancelOn] Events to cancel task on. Applies only to `&#64;ember/component`
   * @param {boolean} [options.enqueue] Sets `enqueue` modifier on task if `true`
   * @param {boolean} [options.evented] Enables [task lifecycle events](/docs/advanced/lifecycle-events) for this Task, if `true`
   * @param {boolean} [options.debug] Enables task debugging if `true`
   * @param {boolean} [options.drop] Sets `drop` modifier on task if `true`
   * @param {string} [options.group] Associates task with the group specified
   * @param {boolean} [options.keepLatest] Sets `keepLatest` modifier on task if `true`
   * @param {number} [options.maxConcurrency] Sets the maximum number of running task instances for the task
   * @param {string|string[]} [options.observes] Properties to watch and cause task to be performed when they change
   * @param {string|string[]} [options.on] Events to perform task on. Applies only to `&#64;ember/component`
   * @param {function?} [options.onState] Callback to use for state tracking. May be set to `null` to disable state tracking.
   * @param {boolean} [options.restartable] Sets `restartable` modifier on task if `true`
   * @return {Task}
   */
  var task = _exports.task = (0, _taskDecorators.createTaskDecorator)({}, _taskFactory.TaskFactory);

  /**
   * Turns the decorated generator function into a task and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, or `group`.
   *
   * You can also define an
   * <a href="/docs/advanced/encapsulated-task">Encapsulated Task</a>
   * by decorating an object that defines a `perform` generator
   * method.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task, dropTask } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;task
   *   *plainTask() {}
   *
   *   &#64;dropTask({ cancelOn: 'click' })
   *   *myDropTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task modifier options. See {@link task} for list.
   * @return {Task}
   */
  var dropTask = _exports.dropTask = (0, _taskDecorators.createTaskDecorator)({
    drop: true
  }, _taskFactory.TaskFactory);

  /**
   * Turns the decorated generator function into a task and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, or `group`.
   *
   * You can also define an
   * <a href="/docs/advanced/encapsulated-task">Encapsulated Task</a>
   * by decorating an object that defines a `perform` generator
   * method.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task, enqueueTask } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;task
   *   *plainTask() {}
   *
   *   &#64;enqueueTask({ cancelOn: 'click' })
   *   *myEnqueueTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task modifier options. See {@link task} for list.
   * @return {Task}
   */
  var enqueueTask = _exports.enqueueTask = (0, _taskDecorators.createTaskDecorator)({
    enqueue: true
  }, _taskFactory.TaskFactory);

  /**
   * Turns the decorated generator function into a task and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, or `group`.
   *
   * You can also define an
   * <a href="/docs/advanced/encapsulated-task">Encapsulated Task</a>
   * by decorating an object that defines a `perform` generator
   * method.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task, keepLatestTask } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;task
   *   *plainTask() {}
   *
   *   &#64;keepLatestTask({ cancelOn: 'click' })
   *   *myKeepLatestTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task modifier options. See {@link task} for list.
   * @return {Task}
   */
  var keepLatestTask = _exports.keepLatestTask = (0, _taskDecorators.createTaskDecorator)({
    keepLatest: true
  }, _taskFactory.TaskFactory);

  /**
   * Turns the decorated generator function into a task and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, or `group`.
   *
   * You can also define an
   * <a href="/docs/advanced/encapsulated-task">Encapsulated Task</a>
   * by decorating an object that defines a `perform` generator
   * method.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task, restartableTask } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;task
   *   *plainTask() {}
   *
   *   &#64;restartableTask({ cancelOn: 'click' })
   *   *myRestartableTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task modifier options. See {@link task} for list.
   * @return {Task}
   */
  var restartableTask = _exports.restartableTask = (0, _taskDecorators.createTaskDecorator)({
    restartable: true
  }, _taskFactory.TaskFactory);

  /**
   * "Task Groups" provide a means for applying
   * task modifiers to groups of tasks. Once a {@linkcode Task} is declared
   * as part of a group task, modifiers like `drop` or `restartable`
   * will no longer affect the individual `Task`. Instead those
   * modifiers can be applied to the entire group.
   *
   * Turns the decorated property into a task group.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task group. For instance `maxConcurrency` or `keepLatest`.
   *
   * ```js
   * import Component from '@glimmer/component';
   * import { task, taskGroup } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;taskGroup({ maxConcurrency: 5 }) chores;
   *
   *   &#64;task({ group: 'chores' })
   *   *mowLawn() {}
   *
   *   &#64;task({ group: 'chores' })
   *   *doDishes() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task group modifier options. See {@link task} for list.
   * @return {TaskGroup}
   */
  var taskGroup = _exports.taskGroup = (0, _taskDecorators.createTaskGroupDecorator)({}, _taskFactory.TaskFactory);

  /**
   * Turns the decorated property into a task group and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}] Task group modifier options. See {@link task} for list.
   * @return {TaskGroup}
   */
  var dropTaskGroup = _exports.dropTaskGroup = (0, _taskDecorators.createTaskGroupDecorator)({
    drop: true
  }, _taskFactory.TaskFactory);

  /**
   * Turns the decorated property into a task group and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}] Task group modifier options. See {@link task} for list.
   * @return {TaskGroup}
   */
  var enqueueTaskGroup = _exports.enqueueTaskGroup = (0, _taskDecorators.createTaskGroupDecorator)({
    enqueue: true
  }, _taskFactory.TaskFactory);

  /**
   * Turns the decorated property into a task group and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}] Task group modifier options. See {@link task} for list.
   * @return {TaskGroup}
   */
  var keepLatestTaskGroup = _exports.keepLatestTaskGroup = (0, _taskDecorators.createTaskGroupDecorator)({
    keepLatest: true
  }, _taskFactory.TaskFactory);

  /**
   * Turns the decorated property into a task group and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}] Task group modifier options. See {@link task} for list.
   * @return {TaskGroup}
   */
  var restartableTaskGroup = _exports.restartableTaskGroup = (0, _taskDecorators.createTaskGroupDecorator)({
    restartable: true
  }, _taskFactory.TaskFactory);
});