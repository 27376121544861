define("ember-pikaday/helpers/pikaday", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.openDatepicker = void 0;
  (true && !(true) && Ember.deprecate('Pikaday helpers from `ember-pikaday/helpers/pikaday` will be removed in the next major version; please use `async/await` helpers from `ember-pikaday/test-support`', true, {
    id: 'ember-pikaday.test-support-helpers',
    until: '3.0.0'
  }));
  var openDatepicker = _exports.openDatepicker = function openDatepicker(element) {
    var pickerElement = document.querySelector(element);
    if (pickerElement) {
      pickerElement.click();
      return PikadayInteractor;
    }
  };
  var MONTH_SELECTOR = '.pika-lendar .pika-select-month';
  var YEAR_SELECTOR = '.pika-lendar .pika-select-year';
  var PikadayInteractor = {
    selectDate: function selectDate(date) {
      var day = date.getDate();
      var month = date.getMonth();
      var year = date.getFullYear();
      var selectEvent = 'ontouchend' in document ? 'touchend' : 'mousedown';
      var yearSelectorElements = document.querySelectorAll(YEAR_SELECTOR);
      updateElementValues(yearSelectorElements, year);
      triggerNativeEvent(yearSelectorElements[0], 'change');
      var monthSelectorElements = document.querySelectorAll(MONTH_SELECTOR);
      updateElementValues(monthSelectorElements, month);
      triggerNativeEvent(monthSelectorElements[0], 'change');
      triggerNativeEvent(document.querySelector("td[data-day=\"'".concat(day, "'\"]:not(.is-outside-current-month) button}")), selectEvent);
    },
    selectedDay: function selectedDay() {
      return document.querySelector('.pika-single td.is-selected button').innerHTML;
    },
    selectedMonth: function selectedMonth() {
      return document.querySelector(MONTH_SELECTOR).value;
    },
    selectedYear: function selectedYear() {
      return document.querySelector(YEAR_SELECTOR).value;
    },
    minimumYear: function minimumYear() {
      return document.querySelector(YEAR_SELECTOR).firstChild.value;
    },
    maximumYear: function maximumYear() {
      document.querySelector(YEAR_SELECTOR).lastChild.value;
    }
  };
  function updateElementValues(elements, value) {
    elements.forEach(function (e) {
      e.value = value;
    });
  }
  function triggerNativeEvent(element, eventName) {
    if (document.createEvent) {
      var event = document.createEvent('Events');
      event.initEvent(eventName, true, false);
      element.dispatchEvent(event);
    } else {
      element.fireEvent('on' + eventName);
    }
  }
});