define("ember-bootstrap/utils/dom", ["exports", "require"], function (_exports, _require) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findElemementByIdInShadowDom = findElemementByIdInShadowDom;
  _exports.findElementById = findElementById;
  _exports.getDOM = getDOM;
  _exports.getDestinationElement = getDestinationElement;
  _exports.getParentView = getParentView;
  function childNodesOfElement(element) {
    var children = [];
    var child = element.firstChild;
    while (child) {
      children.push(child);
      child = child.nextSibling;
    }
    return children;
  }
  function findElementById(doc, id) {
    if (doc.getElementById) {
      return doc.getElementById(id);
    }
    var nodes = childNodesOfElement(doc);
    var node;
    while (nodes.length) {
      node = nodes.shift();
      if (node.getAttribute && node.getAttribute('id') === id) {
        return node;
      }
      nodes = childNodesOfElement(node).concat(nodes);
    }
  }

  // Private Ember API usage. Get the dom implementation used by the current
  // renderer, be it native browser DOM or Fastboot SimpleDOM
  function getDOM(context) {
    var _renderer;
    var renderer = context.renderer;
    if (!((_renderer = renderer) !== null && _renderer !== void 0 && _renderer._dom)) {
      // pre glimmer2
      var container = Ember.getOwner ? Ember.getOwner(context) : context.container;
      var documentService = container.lookup('service:-document');
      if (documentService) {
        return documentService;
      }
      renderer = container.lookup('renderer:-dom');
    }
    if (renderer._dom && renderer._dom.document) {
      return renderer._dom.document;
    } else {
      throw new Error('Could not get DOM');
    }
  }
  function getParentView(el) {
    return closest(el, '.ember-view');
  }

  // taken from https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
  function closest(el, selector) {
    do {
      if (el.matches(selector)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  }
  function getDestinationElement(context) {
    var dom = getDOM(context);
    var id = 'ember-bootstrap-wormhole';
    var destinationElement = findElementById(dom, id) || findElemementByIdInShadowDom(context, id);
    if (true /* DEBUG */ && !destinationElement) {
      var config = Ember.getOwner(context).resolveRegistration('config:environment');
      if (config.environment === 'test' && typeof FastBoot === 'undefined') {
        var _id;
        if (_require.default.has('@ember/test-helpers/dom/get-root-element')) {
          try {
            _id = (0, _require.default)('@ember/test-helpers/dom/get-root-element').default().id;
          } catch (ex) {
            // no op
          }
        }
        if (!_id) {
          return document.querySelector('#ember-testing');
        }
        return document.getElementById(_id);
      }
      (true && Ember.warn("No wormhole destination element found for component ".concat(context, ". If you have set `insertEmberWormholeElementToDom` to false, you should insert a `div#ember-bootstrap-wormhole` manually!"), false, {
        id: 'ember-bootstrap.no-destination-element'
      }));
    }
    return destinationElement;
  }
  function findElemementByIdInShadowDom(context, id) {
    var owner = Ember.getOwner(context);
    return owner.rootElement.querySelector && owner.rootElement.querySelector("[id=\"".concat(id, "\"]"));
  }
});