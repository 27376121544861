define("ember-truth-helpers/helpers/or", ["exports", "ember-truth-helpers/utils/truth-convert"], function (_exports, _truthConvert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.or = or;
  function or(params) {
    for (var i = 0, len = params.length; i < len; i++) {
      if ((0, _truthConvert.default)(params[i]) === true) {
        return params[i];
      }
    }
    return params[params.length - 1];
  }
  var _default = _exports.default = Ember.Helper.helper(or);
});