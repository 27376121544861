define("ember-bootstrap/components/bs-modal/header/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button type="button" aria-label="Close" class="close" ...attributes {{on "click" (bs-default @onClick (bs-noop))}}>
    <span aria-hidden="true">&times;</span>
  </button>
  */
  {
    "id": "/eRWOC5Z",
    "block": "{\"symbols\":[\"&attrs\",\"@onClick\"],\"statements\":[[11,\"button\"],[24,\"aria-label\",\"Close\"],[24,0,\"close\"],[17,1],[24,4,\"button\"],[4,[38,2],[\"click\",[30,[36,1],[[32,2],[30,[36,0],null,null]],null]],null],[12],[2,\"\\n  \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[12],[2,\"×\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"bs-noop\",\"bs-default\",\"on\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/components/bs-modal/header/close.hbs"
    }
  });
  /**
  
   @class ModalHeaderClose
   @namespace Components
   @extends Glimmer.Component
   @private
   */

  /**
   * @event onClick
   * @public
   */
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});