define("ember-cli-mirage/deprecate-reexports", ["exports", "miragejs", "ember-cli-mirage/index", "@embroider/macros/runtime"], function (_exports, mirage, ecMirageExports, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initDeprecatedReExports = initDeprecatedReExports;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var nonDeprecatedImports = ['default'];

  /**
   @function initDeprecatedReExports
   @hide
   */
  function initDeprecatedReExports() {
    Object.entries(mirage).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        name = _ref2[0],
        value = _ref2[1];
      if (!nonDeprecatedImports.includes(name)) {
        // eslint-disable-next-line no-import-assign
        Object.defineProperty(ecMirageExports, name, {
          get: function get() {
            if ((0, _runtime.isTesting)() && true) {
              var _importSync = require("@ember/test-helpers"),
                waitUntil = _importSync.waitUntil,
                getContext = _importSync.getContext;
              window.QUnit.begin(function () {
                // Make sure deprecation message does not get "swallowed"
                // when tests run due to
                // https://github.com/emberjs/ember-test-helpers/blob/master/addon-test-support/%40ember/test-helpers/setup-context.ts#L41
                waitUntil(function () {
                  return getContext() !== undefined;
                }).then(function () {
                  return _deprecate(name);
                });
              });
            } else {
              _deprecate(name);
            }
            return value;
          },
          enumerable: true
        });
      }
    });
  }
  function _deprecate(name) {
    var message = "Importing '".concat(name, "' from 'ember-cli-mirage' is deprecated.") + " Install the `miragejs` package and use " + "`import { ".concat(name, " } from 'miragejs';` instead.");
    (true && !(false) && Ember.deprecate(message, false, {
      id: 'ember-cli-mirage.miragejs.import',
      until: '3.0.0',
      for: 'ember-cli-mirage',
      since: {
        enabled: '2.3.0'
      }
    }));
  }
});