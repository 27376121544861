define("ember-bootstrap/utils/transition-end", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = waitForTransitionEnd;
  _exports.skipTransition = skipTransition;
  var _skipTransition;
  function skipTransition(bool) {
    _skipTransition = bool;
  }
  function _isSkipped() {
    return _skipTransition === true | _skipTransition !== false && Ember.testing;
  }
  function waitForTransitionEnd(node) {
    var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    if (!node) {
      return Ember.RSVP.reject();
    }
    var backup;
    if (_isSkipped()) {
      duration = 0;
    }
    return new Ember.RSVP.Promise(function (resolve) {
      var _done = function done() {
        if (backup) {
          Ember.run.cancel(backup);
          backup = null;
        }
        node.removeEventListener('transitionend', _done);
        resolve();
      };
      node.addEventListener('transitionend', _done, false);
      backup = Ember.run.later(this, _done, duration);
    });
  }
});